<template>
  <v-dialog
    v-model="dialog"
    persistent
    width="900"
    scrollable
  >
    <v-card>
      <v-card-title class="caption">
        <v-row>
          <v-col class="d-flex pa-0 ma-0 justify-end">
            <v-toolbar-items>
              <v-btn
                icon
                color="black"
                dark
                @click="dialog = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="mt-4">
        <v-row>
          <v-col cols="12" v-if="companyType !== 'admin'">
            <v-row>
              <v-col cols="12" sm="3">
                <label for="companyId">Shipper/Transporter</label>
              </v-col>
              <v-col cols="12" sm="5">
                <common-auto-complete-items
                  :type="companyType === 'shipper' ? 'masterShipper' : 'masterPartner'"
                  searchName="companyName"
                  item-value="id"
                  item-text="companyName"
                  hide-details
                  height="10"
                  v-model="companyId"
                  placeholder="Pilih Perusahaan"
                  dense
                  outlined
                  class="caption"
                  clearable
                  @change="fetchData"
                  @updateItems="updateCompanyItems"
                  :filter="filterCompany"
                  :items="companyItems"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12">
            <v-data-table
              :loading="isLoading"
              :headers="headers"
              :items="items"
              :server-items-length="totalData"
              :options.sync="pagination"
              :footer-props="{showCurrentPage: true, showFirstLastPage: true, 'items-per-page-options': $_item_per_page}"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  skipEmptyStringObject,
} from '@/helper/commonHelpers';

export default {
  props: {
    shipmentId: Number,
  },
  data() {
    return {
      dialog: false,
      isLoading: false,
      filter: {},
      headers: [
        {
          text: 'NO',
          class: 'white--text primary',
          value: 'no',
          width: '10%',
          sortable: false,
        },
        {
          text: 'Nama User',
          class: 'white--text primary',
          value: 'username',
          width: '10%',
          sortable: false,
        },
        {
          text: 'Email',
          class: 'white--text primary',
          value: 'email',
          width: '25%',
          sortable: false,
        },
      ],
      items: [],
      companyType: 'web_management',
      totalData: 0,
      pagination: {
        page: 1,
        itemsPerPage: 10,
      },
      companyId: null,
      companyItems: [],
      filterCompany: {
        search: '',
        page: 0,
        size: 25,
        totalData: 0,
      },
    };
  },
  watch: {
    pagination: {
      handler() {
        if (!this.isLoading) this.fetchData();
      },
      deep: true,
    },
    dialog(newVal) {
      if (!newVal) {
        this.items = [];
        this.totalData = 0;
        this.filterCompany = {
          search: '',
          page: 0,
          size: 25,
          totalData: 0,
        };
        this.companyItems = [];
        this.companyId = null;
      }
      if (newVal) {
        this.fetchData();
      }
    },
  },
  methods: {
    updateCompanyItems(items) {
      this.companyItems = [...this.companyItems, ...items];
    },
    async fetchData() {
      if (this.companyType !== 'admin' && !this.companyId) return;
      try {
        const {
          page, itemsPerPage,
        } = this.pagination;
        const filter = skipEmptyStringObject({
          page: page - 1,
          size: itemsPerPage,
          ...this.filter,
        });
        this.isLoading = true;
        let result;
        if (this.companyType === 'admin') {
          result = await this.$_services.userAdmins.fetchList(filter);
        } else {
          result = await this.$_services.role.getUsersRole({ companyType: this.companyType, companyId: this.companyId, filter });
        }
        this.items = result.data.contents.map((i, index) => ({
          ...i,
          no: (page - 1) * itemsPerPage + index + 1,
        }));
        this.totalData = result.data.totalData;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
