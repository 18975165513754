<template>
  <v-row>
    <v-col cols="12" sm="8">
      <v-autocomplete
        v-model="filter.appType"
        :items="items"
        dense
        outlined
        class="caption"
        placeholder="Role App Type"
      >
      </v-autocomplete>
    </v-col>
    <v-col cols="auto">
      <v-btn
        color="primary"
        outlined
        @click="handleFilter"
      >
        Filter
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'filter-list-role',
  props: {
    filter: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      items: [
        {
          value: 'web_management',
          text: 'Web Management',
        },
        {
          value: 'web_transporter',
          text: 'Web Transporter',
        },
        {
          value: 'web_shipper',
          text: 'Web Shipper',
        },
      ],
    };
  },
  methods: {
    handleFilter() {
      this.$router.replace({
        query: {
          appType: this.filter.appType,
        },
      });
      this.$emit('refetch');
    },
  },
};

</script>
