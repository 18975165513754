<template>
  <section>
    <v-data-table
      :loading="isLoading"
      :headers="displayedHeader"
      :items="items"
      item-key="id"
      :server-items-length="totalData"
      :options.sync="pagination"
      :footer-props="{showCurrentPage: true, showFirstLastPage: true, 'items-per-page-options': $_item_per_page}"
    >
      <template v-slot:[`item.rolesName`]="{item}">
        <span>{{ item.rolesName }}</span>
      </template>
      <template v-slot:[`item.users`]="{item}">
        <v-tooltip top v-if="userAccess.canUpdate">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              x-small
              icon
              class="mr-2"
              color="primary"
              @click="() => showDialogUserList(item)"
            >
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </template>
          <span>{{$_strings.common.VIEW}}</span>
        </v-tooltip>
      </template>
      <template v-slot:[`item.action`]="{item}">
        <v-tooltip top v-if="userAccess.canUpdate">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              x-small
              icon
              class="mr-2"
              color="primary"
              @click="() => showDialogCreateEditRole(item)"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>{{$_strings.common.EDIT}}</span>
        </v-tooltip>
        <v-tooltip top v-if="userAccess.canUpdate">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              x-small
              icon
              class="mr-2"
              color="primary"
              @click="() => navigateToDetailRole(item)"
            >
              <v-icon>mdi-file-document</v-icon>
            </v-btn>
          </template>
          <span>{{$_strings.common.DETAIL}}</span>
        </v-tooltip>
      </template>
      <template v-slot:[`footer.page-text`]="props">
        <span>
          List Role
          <span v-if="items.length">
            {{props.pageStart}}-{{props.pageStop}} of {{props.itemsLength}}
          </span>
        </span>
      </template>
    </v-data-table>
    <DialogCreateEditRole
      ref="dialogCreateEditRole"
      @fetchData="fetchData"
    />
    <DialogUserList
      ref="dialogUserList"
    />
  </section>
</template>

<script>
import {
  skipEmptyStringObject,
  handleSortBy,
  handlerPagination,
  defaultPagination,
} from '@/helper/commonHelpers';

import DialogCreateEditRole from './Dialog/CreateEditRole.vue';
import DialogUserList from './Dialog/UserList.vue';

export default {
  name: 'table-list-role',
  components: {
    DialogCreateEditRole,
    DialogUserList,
  },
  props: {
    filter: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isLoading: false,
      headers: [
        {
          text: 'No',
          value: 'no',
          width: '10%',
          sortable: false,
        },
        {
          text: 'Role Name',
          value: 'rolesName',
          width: '25%',
        },
        {
          text: 'Users',
          value: 'users',
          width: '25%',
          sortable: false,
        },
        {
          text: 'Action',
          value: 'action',
          width: '25%',
          sortable: false,
        },
      ],
      items: [],
      totalData: 0,
      pagination: defaultPagination(),
    };
  },
  computed: {
    displayedHeader() {
      const { canUpdate } = this.userAccess;
      return this.headers.map((i) => ({
        ...i,
        class: 'white--text primary text-capitalize',
      })).filter((header) => {
        if (!canUpdate) {
          return header.value !== 'action';
        }
        return header;
      });
    },
  },
  watch: {
    pagination: {
      handler(newVal) {
        handlerPagination(this, newVal);
        if (!this.isLoading) this.fetchData();
      },
      deep: true,
    },
  },
  methods: {
    convertRolesName(rolesName) {
      return rolesName.replace(/_/g, ' ');
    },
    navigateToDetailRole(item) {
      const { appType } = this.filter;
      this.$router.push({
        name: 'detail-role',
        params: {
          roleId: item.rolesId,
        },
        query: {
          rolesName: item.rolesName,
          appType,
        },
      });
    },
    showDialogUserList(item) {
      const companyType = {
        web_management: 'admin',
        web_transporter: 'transporter',
        web_shipper: 'shipper',
      };
      this.$refs.dialogUserList.companyType = companyType[this.filter.appType];
      this.$refs.dialogUserList.filter = {
        rolesId: item.rolesId,
      };
      this.$refs.dialogUserList.dialog = true;
    },
    showDialogCreateEditRole(item) {
      if (item) {
        this.$refs.dialogCreateEditRole.id = item.rolesId;
        this.$refs.dialogCreateEditRole.form.rolesName = item.rolesName;
        this.$refs.dialogCreateEditRole.form.appType = this.filter.appType;
      }
      this.$refs.dialogCreateEditRole.dialog = true;
    },
    async fetchData() {
      const {
        page, itemsPerPage, sortBy, sortDesc,
      } = this.pagination;
      const filter = skipEmptyStringObject({
        page: page - 1,
        size: itemsPerPage,
        ...this.filter,
      });
      try {
        this.isLoading = true;
        const result = await this.$_services.role.fetchRoles({ filter });
        this.items = result.data.contents.map((i, index) => ({
          ...i,
          no: (page - 1) * itemsPerPage + index + 1,
        }));
        this.totalData = result.data.totalData;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
